<template>
  <component :is="salesTargetData ? 'b-card' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="salesTargetData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching Sales Target data
      </h4>
      <div class="alert-body">
        No sales target found with this sales target id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'sales-targets-list'}"
        >
          Sales Targets List
        </b-link>
        for other sales targets.
      </div>
    </b-alert>

    <validation-observer
      v-if="salesTargetData"
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(updateSalesTarget)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <sales-targets-form
          :sales-target-data="salesTargetData"
          :status-options="statusOptions"
          :account-type-options="accountTypeOptions"
          :form-type="'edit'"
        />

      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BCard, BAlert, BLink, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useSalesTargetsForm from '../sales-targets-form/useSalesTargetsForm'
import SalesTargetsForm from '../sales-targets-form/SalesTargetsForm.vue'
import { statusOptions, accountTypeOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import useSystemCodes from '../../../system-settings/system-codes/useSystemCodes'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BForm,
    BButton,

    // Local Component
    SalesTargetsForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const {
      salesTargetData,

      fetchSalesTarget,
      updateSalesTarget,
    } = useSalesTargetsForm()

    const { callAccountType } = useSystemCodes()
    callAccountType()

    fetchSalesTarget()

    return {
      //  Data
      salesTargetData,

      //   Options
      statusOptions,
      accountTypeOptions,

      //  Actions
      fetchSalesTarget,
      updateSalesTarget,
    }
  },
}
</script>
